<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="PRODUCT"
        :filter="filter"
        placeholder="Search Name, Barcode, Article No."
        @sidebar="sidebarFilter"
        @search="handleSearch"
        :hasDropdown="true"
        @downloadTemplate="downloadTemplate"
        @exportFile="exportFile"
        routerPath="/product/detail/0"
      >
        <template v-slot:content-dropdown>
          <b-dropdown-item @click="importFile(true)">
            <span>
              <!-- <font-awesome-icon icon="file-import" class="pointer mr-2" /> -->
            </span>
            <span>Import Product </span>
          </b-dropdown-item>
          <b-dropdown-item @click="importFile(false)">
            <span>
              <!-- <font-awesome-icon icon="file-import" class="pointer mr-2" /> -->
            </span>
            <span>Import AH </span>
          </b-dropdown-item>
          <b-dropdown-item @click="exportFile">
            <span>
              <!-- <font-awesome-icon icon="file-export" class="pointer mr-2" /> -->
            </span>
            <span>Export (.xlsx) </span>
          </b-dropdown-item>
          <b-dropdown-item @click="downloadTemplate">
            <span>
              <!-- <font-awesome-icon icon="file-download" class="pointer mr-2" /> -->
            </span>
            <span>Download Template</span>
          </b-dropdown-item>
          <b-dropdown-item @click="downloadAHTemplate">
            <span>
              <!-- <font-awesome-icon icon="file-download" class="pointer mr-2" /> -->
            </span>
            <span>Download AH Template</span>
          </b-dropdown-item>
        </template></HeaderPanel
      >

      <TableProductPromotionList
        class="mt-3"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :showing="showing"
        :showingTo="showingTo"
        :rows="rows"
        :pageOptions="pageOptions"
        :filter="filter"
        @handleChangeTake="handleChangeTake"
        @page="page"
      />
      <SidebarFilterProductPromotion
        ref="SidebarFilterProduct"
        :productType="productType"
        :status="status"
        :filter="filter"
        @submitFilter="submitFilter"
      />
      <b-modal
        v-model="modalImport"
        no-close-on-backdrop
        centered
        title="Import Product"
        hide-footer
      >
        <div>
          <b-row class="no-gutters">
            <b-col md="12">
              <UploadFileV2
                textFloat="File"
                placeholder="Please Choose File"
                format="excel"
                name="file"
                text="*Please upload only file .xlsx less than 10 MB"
                isRequired
                v-on:onFileChange="onFileChange"
                v-on:delete="deleteFile"
                :fileName="form.file_name"
                v-model="form.excel_file"
                :v="$v.form.excel_file"
                id="uploadfile"
              />
            </b-col>
            <b-col cols="12">
              <InputText
                textFloat="Email"
                placeholder="Email"
                type="text"
                name="email"
                isRequired
                v-model="form.email"
                :v="$v.form.email"
                :isValidate="$v.form.email.$error"
            /></b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-button
                size="md"
                class="btn-cancel"
                @click="modalImport = false"
                >Cancel</b-button
              >
            </b-col>
            <b-col class="text-right">
              <b-button
                id="upload"
                class="main-color btn-save color-btn"
                size="md"
                :disabled="isDisable"
                @click="importFiles"
                >Upload</b-button
              >
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import { required, email } from "vuelidate/lib/validators";
import TableProductPromotionList from "@/components/productPromotion/TableProductPromotionList";
import SidebarFilterProductPromotion from "@/components/productPromotion/SidebarFilterProductPromotion";
export default {
  components: {
    TableProductPromotionList,
    SidebarFilterProductPromotion,
    OtherLoading,
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        excel_file: {
          required,
        },
      },
    };
  },
  data() {
    return {
      isDisable: false,
      filter: {
        search: null,
        product_type: [],
        status: [],
        take: 10,
        page: 1,
      },
      form: {
        email: "",
        file_name: "",
        excel_file: "",
        user_guid: "",
      },
      mode: false,
      modalImport: false,
      fields: [
        {
          label: "Image",
          key: "image_url",
          thStyle: { width: "10%" },
          tdClass: "p-3",
        },
        {
          label: "Name/Code/Type",
          key: "name",
          thStyle: { width: "35%" },
        },
        {
          label: "AH",
          key: "ah_no",
        },
        {
          label: "Barcode",
          key: "barcode",
        },
        {
          label: "Product Type",
          key: "product_type",
        },
        {
          label: "Price",
          key: "price",
        },
        {
          label: "Status",
          key: "status",
        },
      ],
      items: [],
      isBusy: true,
      showing: 1,
      showingTo: 10,
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      productType: [
        {
          id: "ZFIN",
          name: "ZFIN",
        },
        {
          id: "ZSAM",
          name: "ZSAM",
        },
        {
          id: "ZLPR",
          name: "ZLPR",
        },
      ],
      status: [
        {
          id: 1,
          name: "Active",
        },
        {
          id: 0,
          name: "Inactive",
        },
      ],
      isLoading: false,
      filterExport: {
        search: null,
        product_type: [],
        status: [],
        from_price: 0,
        to_price: 0,
      },
    };
  },
  async created() {
    await this.getProductList();
  },
  methods: {
    async getProductList() {
      this.isBusy = true;
      await this.$store.dispatch("getProductPromotionList", this.filter);
      const data = this.$store.state.product.stateProductPromotionList;
      if (data.result === 1) {
        this.items = data.detail.product_list;
        this.rows = data.detail.total_count;
        this.isBusy = false;
      }
    },
    sidebarFilter() {
      this.$refs.SidebarFilterProduct.show();
    },
    handleSearch() {
      this.filter.page = 1;
      this.getProductList();
    },
    handleChangeTake(filter) {
      this.filter = filter;
      this.getProductList();
    },
    page(filter) {
      this.filter = filter;
      this.getProductList();
    },
    submitFilter(filter) {
      this.filter = filter;
      this.getProductList();
    },
    createProduct() {
      this.$router.push("/product/detail/0");
    },
    async downloadTemplate() {
      this.$bus.$emit("showLoading");
      await this.$store.dispatch("downloadTemplateProduct");
      const data = this.$store.state.product.stateDownloadTemplateProduct;
      var fileURL = window.URL.createObjectURL(new Blob([data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.$bus.$emit("hideLoading");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Product-Template-List-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async downloadAHTemplate() {
      this.$bus.$emit("showLoading");
      const data = await this.axios(
        `/ProductMenu/ah_template`,

        {
          responseType: "blob",
        }
      );
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      this.$bus.$emit("hideLoading");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `Product-AH-Template-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    async exportFile() {
      this.$bus.$emit("showLoading");
      this.filterExport.search = this.filter.search;
      this.filterExport.product_type = this.filter.product_type;
      this.filterExport.status = this.filter.status;
      await this.$store.dispatch("exportProductList", this.filterExport);
      const data = this.$store.state.product.stateExportProductList;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Product-` + dateExcel + `.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.$bus.$emit("hideLoading");
    },
    importFile(val) {
      this.form = {
        email: this.$cookies.get("back_office_admin_email"),
        file_name: "",
        excel_file: "",
        user_guid: "",
      };
      this.$v.form.$reset();
      this.modalImport = true;
      this.mode = val;
    },
    onFileChange(file) {
      this.form.file_name = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
    deleteFile(value) {
      this.form = {
        file_name: "",
        excel_file: "",
      };
    },
    async importFiles() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$bus.$emit("showLoading");
      var res = null;
      if (this.mode) {
        res = await this.axios.post(`/import/importexcelproduct`, this.form);
      } else {
        res = await this.axios.post(`/import/importexcelah`, this.form);
      }

      this.$bus.$emit("hideLoading");
      if (res.data.result) {
        this.modalImport = false;
        this.getProductList();
        this.successAlert();
      } else {
        this.errorAlert(res.data.message);
      }
    },
  },
};
</script>
